import React from 'react';

const Teaser = () => (
  <div className="row eh-grid">
    <div className="col-sm-3">
      <div
        className="post pattern-bg"
        style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
      >
        <div className="post-media">
          <img
            src="/design/img/deeg.jpg"
            alt="Overheerlijke pizza's met de typische italiaanse krokante dunne bodem"
            className="img-responsive"
          />
        </div>
        <div className="post-content">
          <h4 className="title">
            Overheerlijke pizza’s met de typisch krokante, dunne bodem
          </h4>
        </div>
      </div>
    </div>
    <div className="col-sm-3">
      <div
        className="post pattern-bg"
        style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
      >
        <div className="post-media">
          <img
            src="/design/img/oven.jpg"
            alt="Onze zelf ontwikkelde mobiele pizzabus"
            className="img-responsive"
          />
        </div>
        <div className="post-content">
          <h4 className="title">
            Bereid in onze authentieke mobiele Italiaanse houtoven(s)
          </h4>
        </div>
      </div>
    </div>
    <div className="col-sm-3">
      <div
        className="post pattern-bg"
        style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
      >
        <div className="post-media">
          <img
            src="/design/img/ingredients.jpg"
            alt="Altijd verse ingrediënten van de beste kwaliteit"
            className="img-responsive"
          />
        </div>
        <div className="post-content">
          <h4 className="title">
            Met dagvers bereide deeg en verse ingrediënten van topkwaliteit
          </h4>
        </div>
      </div>
    </div>
    <div className="col-sm-3">
      <div
        className="post pattern-bg"
        style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
      >
        <div className="post-media">
          <img
            src="/design/img/plates.jpg"
            alt="Wij voorzien alles, van pizza's tot bestek"
            className="img-responsive"
          />
        </div>
        <div className="post-content">
          <h4 className="title">
            Op de door u gekozen locatie, ruime keuze en 'à volonté'
          </h4>
        </div>
      </div>
    </div>
  </div>
);

export default Teaser;
