import React from 'react';

import Layout from '../components/layout';
import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Mobiel pizzabus | Pizzafeest | Pizza op locatie"
      description="Attorno staat garant voor een evenement dat bijzonder origineel is
      en de nodige klasse naar de gasten toe uitstraalt. Onze mobiele
      houtovens hebben een stenen vloer waardoor pizza’s kunnen gebakken
      worden met een overheerlijke krokante bodem."
    />

    <Nav />
    <Header />

    <section
      className="pt-8 pb-5 text-center zero-bottom pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row no-dev">
          <div className="col-sm-8 col-sm-offset-2 sep-bottom editContent">
            <h2 className="title">Een origineel feest organiseren?</h2>
            <p>
              Attorno staat garant voor een evenement dat bijzonder origineel is
              en de nodige klasse naar de gasten toe uitstraalt. Onze mobiele
              houtovens hebben een stenen vloer waardoor pizza’s kunnen gebakken
              worden met een overheerlijke krokante bodem. De mooie Italiaanse
              oven kan bovendien, indien gewenst, dicht bij uw feestvierenden
              geplaatst worden, wat echt veel bijdraagt tot de gezellige sfeer
              van je feest. Neem daarbij ook nog de gedrevenheid en
              vriendelijkheid van al onze medewerkers en je verjaardags– of
              huwelijksfeest of nog je personeelsfeest of babyshower of …
              behaalt zeker het succes dat het verdient.
            </p>
          </div>
          <div className="col-sm-12">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze authentieke oven"
            />
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default IndexPage;
